import PropTypes from "prop-types";
import React from "react";
import { ThemeContext } from "../layouts";
import { Typography, Row } from "antd";
import "antd/dist/antd.css";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
const { Title, Paragraph } = Typography;
import servicios from "../../content/datos-servicios";
import Servicio from "../components/Servicio";
import ColumnServicio from "../components/ColumnServicio";

const CategoryPage = props => {
  return (
    <div>
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Article theme={theme}>
              <header>
                <Headline title="Servicios" theme={theme} />
              </header>

              <Paragraph>
                Construimos soluciones a medida, nuestro modelo de trabajo iterativo permite ajustar
                el software mientras se está construyendo, esto produce sistemas que siempre que
                ajustan al 100% con los requerimientos de los clientes.
              </Paragraph>

              <Row gutter={8}>{servicios.map(s => <ColumnServicio key={s.nombre} {...s} />)}</Row>

              <Title style={{ marginTop: "2em" }} level={2}>
                Tecnologías
              </Title>

              {servicios.map(s => <Servicio key={s.nombre} {...s} />)}
            </Article>
          )}
        </ThemeContext.Consumer>
      </React.Fragment>
    </div>
  );
};

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default CategoryPage;
